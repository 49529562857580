import PropTypes from 'prop-types';

const SEND_KEYS = ['shiftKey', 'ctrlKey'];
const TIMESTAMP_FORMAT = ['calendar', 'fromNow'];

export const onSendKeyType = PropTypes.oneOf(SEND_KEYS);

export const timestampFormatType = PropTypes.oneOf(TIMESTAMP_FORMAT);

export const authorType = PropTypes.shape({
  id: PropTypes.number,
  username: PropTypes.string,
  avatarUrl: PropTypes.string,
});
