import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './float.module.css';
// import styles from './inputs.module.css';

const FloatInput = ({
  id,
  type,
  step,
  label,
  value,
  onChange,
  onBlur,
  append,
  prepend,
  isInvalid,
  invalidText,
  disabled,
  required,
  placeholder,
}) => {
  const labelClass = value ? styles.floatFilled : styles.floatLabel;
  // const Id = label.toLowerCase().replace(' ', '-')
  // const className = `${styles.select} form-control`

  // prepend = prepend ? <span>{prepend}</span> : null;
  // append = append ? <span>{append}</span> : null;

  return (
    <div className="position-relative">
      <InputGroup hasValidation={isInvalid}>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Form.Control
          id={id}
          type={type}
          step={step}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          className={styles.floatField}
          isInvalid={isInvalid}
          disabled={disabled}
          required={required}
        />
        {append && (
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {invalidText || 'Invalid'}
          </Form.Control.Feedback>
        )}
      </InputGroup>
      <Form.Label
        className={labelClass}
        style={prepend && { marginLeft: '2.1rem' }}
      >
        {label}
      </Form.Label>
    </div>
  );
};

FloatInput.defaultProps = {
  type: 'text',
  label: 'Label',
  placeholder: null,
};

export default FloatInput;
