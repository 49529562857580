import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Loans = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/loans/');

  const [loans, setLoans] = useState([]);
  const [newLoan, setNewLoan] = useState();

  useEffect(() => {
    endpoint.list()
      .then((data) => setLoans(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(loans);
    updated[index][field] = value;
    setLoans(updated);
  };

  const handleUpdate = (loan) => {
    endpoint.update(loan.id, loan)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${loan.loaner}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${loan.loaner}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(loans);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setLoans(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.loaner}` }));
  };

  const initLoan = () => setNewLoan({ loaner: '', rate: 0.00, term: 0, fees: 0.00 });
  const handleCreate = () => {
    // send POST request for new credit
    endpoint.create(newLoan)
      .then(() => endpoint.list().then((data) => setLoans(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newLoan.loaner}.` });
        setNewLoan();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new credit or rebate.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={3}>Loan</Col>
        <Col sm={2}>Rate</Col>
        <Col sm={2}>Term</Col>
        <Col sm={3}>Dealer Fees</Col>
      </Row>

      {loans.map((item, index) => (
        <Row key={item.id} className="mt-2">
          <Col sm={3}>
            {/* <Form.Control
              value={newLoan.loaner || ''}
              onChange={(e) => setNewLoan({ ...newLoan, loaner: e.target.value })}
              required
            /> */}
            {item.loaner}
          </Col>
          <Col sm={2}>
            <InputGroup>
              <Form.Control
                type="number"
                value={item.rate}
                onChange={(e) => handleUpdateField(e.target.value, 'rate', index)}
                step="0.01"
                min="0.00"
                required
              />
              <InputGroup.Append>
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col sm={2}>
            <InputGroup>
              <Form.Control
                className="w-75"
                type="number"
                value={item.term}
                onChange={(e) => handleUpdateField(e.target.value, 'term', index)}
                required
              />
            </InputGroup>
          </Col>
          <Col sm={3}>
            <InputGroup>
              <Form.Control
                className="w-75"
                type="number"
                value={item.fees}
                onChange={(e) => handleUpdateField(e.target.value, 'fees', index)}
                step="0.01"
                min="0"
                required
              />
              <InputGroup.Append>
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {newLoan === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={initLoan}>
              + Loan
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-3">
            <Col sm={3}>
              <Form.Control
                value={newLoan.loaner || ''}
                onChange={(e) => setNewLoan({ ...newLoan, loaner: e.target.value })}
                required
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={newLoan.rate || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, rate: e.target.value })}
                  step="0.01"
                  min="0.00"
                  required
                />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col sm={2}>
              <InputGroup>
                <Form.Control
                  className="w-75"
                  type="number"
                  value={newLoan.term || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, term: e.target.value })}
                  required
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputGroup>
                <Form.Control
                  className="w-75"
                  type="number"
                  value={newLoan.fees || ''}
                  onChange={(e) => setNewLoan({ ...newLoan, fees: e.target.value })}
                  step="0.01"
                  min="0"
                  required
                />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewLoan()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Loans;
