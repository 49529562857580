// TODO: Merge with AdditionalFields/ component

import React, {
  useEffect, useRef, useState, useContext, useMemo,
} from 'react';
import {
  Tabs, Tab, Row, Col, Card, Button, Form, Accordion, ButtonGroup, Modal,
} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import {
  CaretDownFill, CaretUpFill, Trash, PencilSquare, Plus, Pencil, ArrowLeft, ArrowRight, ArrowUp, ArrowDown,
} from 'react-bootstrap-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AuthContext } from 'api/context';
import { setTitle } from 'Main/utils';
import './AdditionalFields.css';

const title = 'Fields & Groups';

const AdditionalFields = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const fieldsAPI = newEndpoint('crm/lead/fields/');

  const [{ tabs, groups, fields }, setFields] = useState({ tabs: [], groups: [], fields: [] });
  const [selectedTab, setSelectedTab] = useState(1);

  const [{ modalTabs, modalGroups, modalFields }, setModal] = useState({
    modalTabs: {
      show: false,
      showDelete: false,
      tabName: '',
      id: 0,
    },
    modalGroups: {
      show: false,
      showDelete: false,
      groupName: '',
      type: 'L',
      id: 0,
    },
    modalFields: {
      show: false,
      showDelete: false,
      fieldName: '',
      fieldType: '',
      group: '',
      options: [],
      defaultOption: '',
      type: 'L',
      tab: '',
      id: 0,
    },
  });

  const [accordionIndex, setAccordionIndex] = useState(0);

  const setModalAttribute = (attrs) => {
    setModal((oldModal) => ({ ...oldModal, ...attrs }));
  };

  const ModalNewField = (props) => {
    const txtFieldName = useRef();
    const txtFieldType = useRef();
    const txtFieldGroup = useRef();
    const txtDefaultOption = useRef();
    const txtOptions = useRef([]);

    const [{
      showOptions, showDefault, options, defaultOption,
    }, setOptions] = useState({
      showOptions: false, showDefault: false, options: [], defaultOption: '',
    });

    const addOption = () => {
      setOptions((old) => ({ ...old, options: [...options, ''] }));
    };

    const deleteOption = (index) => {
      options.splice(index, 1);
      setOptions((old) => ({ ...old, options: [...options] }));
    };

    const setOption = (index, value) => {
      options[index] = value;
      setOptions((old) => ({ ...old, options: [...options] }));
    };

    const validate = () => {
      const fieldname = txtFieldName.current.value.trim();
      if (fieldname.length === 0
        || fields.filter(({ name, id = -1 }) => fieldname === name
        && id !== props?.id).length > 0) {
        txtFieldName.current.classList.add('is-invalid');
        txtFieldName.current.focus();
        return false;
      }

      txtFieldName.current.classList.remove('is-invalid');

      if (txtFieldType.current.selectedIndex === 0) {
        txtFieldType.current.classList.add('is-invalid');
        txtFieldType.current.focus();
        return false;
      }

      txtFieldType.current.classList.remove('is-invalid');

      if (txtFieldGroup.current.selectedIndex === 0) {
        txtFieldGroup.current.classList.add('is-invalid');
        txtFieldGroup.current.focus();
        return false;
      }

      txtFieldGroup.current.classList.remove('is-invalid');

      if (showOptions) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [i, value] of options.entries()) {
          if (value.trim().length === 0) {
            txtOptions.current[i].classList.add('is-invalid');
            txtOptions.current[i].focus();
            return false;
          }

          txtOptions.current[i].classList.remove('is-invalid');
        }
      }

      return true;
    };

    const handleClose = () => props.setShow(false);

    const handleAdd = () => {
      if (!validate()) return;

      props.add({
        id: (Math.max(...fields.map((f) => parseFloat(f.id))) + 1).toString(),
        name: txtFieldName.current.value,
        type: txtFieldType.current.value,
        group: txtFieldGroup.current.value,
        fixed: false,
        options,
        defaultOption: txtDefaultOption.current?.value ?? '',
      });

      props.setShow(false);
    };

    const handleEdit = () => {
      if (!validate()) return;

      props.edit({
        id: props.id,
        name: txtFieldName.current.value,
        type: txtFieldType.current.value,
        group: txtFieldGroup.current.value,
        options,
        defaultOption: txtDefaultOption.current?.value ?? '',
      });

      props.setShow(false);
    };

    useEffect(() => {
      if (txtFieldName?.current && props.fieldName !== '') txtFieldName.current.value = props.fieldName;
      if (txtFieldType?.current && props.fieldType !== '') txtFieldType.current.value = props.fieldType;
      if (txtFieldGroup?.current && props.group !== '') txtFieldGroup.current.value = props.group;

      if (txtFieldName?.current) {
        if (props.fixed) {
          txtFieldName.current.disabled = 'disabled';
          txtFieldType.current.disabled = 'disabled';
          txtFieldGroup.current.disabled = 'disabled';
          if (txtDefaultOption.current) txtDefaultOption.current.focus();
        } else {
          txtFieldName.current.disabled = '';
          txtFieldType.current.disabled = '';
          txtFieldGroup.current.disabled = '';
          txtFieldName.current.focus();
        }
      }

      setOptions(
        {
          showOptions: ['Dropdown', 'Radio Buttons', 'Check Box(es)'].includes(props.fieldType),
          showDefault: props.fieldType === 'Dropdown',
          options: props.options,
          defaultOption: props.defaultOption,
        },
      );
    }, [props.defaultOption, props.fieldName, props.fieldType, props.fixed, props.group, props.options]);

    const changeType = (e) => {
      const v = e.target.value;
      const show = ['Dropdown', 'Radio Buttons', 'Check Box(es)'].includes(v);
      setOptions((old) => ({ ...old, showOptions: show, showDefault: v === 'Dropdown' }));
    };

    return (
      <Modal show={props.show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{props.id ? 'Edit Field' : 'Add Field'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label>Field Name</Form.Label>
                <Form.Control placeholder="Field Name" ref={txtFieldName} />
              </Col>
              <Col>
                <Form.Label>Field Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="Choose..."
                  placeholder="Field Type"
                  ref={txtFieldType}
                  onChange={changeType}
                >
                  <option select>Choose...</option>
                  <option>Text</option>
                  <option>Number</option>
                  <option>Currency</option>
                  <option>Date</option>
                  <option>Date and time</option>
                  <option>Dropdown</option>
                  <option>Radio Buttons</option>
                  <option>Check Box(es)</option>
                  <option>Single Radio Button</option>
                  <option>Single Check Box</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="mt-2">Group</Form.Label>
                <Form.Control as="select" defaultValue="Choose..." placeholder="Group" ref={txtFieldGroup}>
                  <option select>Choose...</option>
                  {groups
                    .filter((g) => g.type === props.type && (g.type === 'L' || g.tab === props.tab))
                    .sort((g) => g.index)
                    .map((g) => <option key={g.name}>{g.name}</option>)}
                </Form.Control>
              </Col>
            </Row>
            {showOptions
              ? (
                <>
                  <Row>
                    <Col xs={7}>
                      <Form.Label className="mt-2">Options</Form.Label>
                      {options ? options.map((o, index) => (
                        <InputGroup className="mb-3">
                          <Form.Control
                            ref={(el) => { txtOptions.current[index] = el; }}
                            value={options[index]}
                            onChange={(e) => setOption(index, e.target.value)}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="link"
                              size="sm"
                              className="p-0 m-0"
                              onClick={() => deleteOption(index)}
                            >
                              <Trash className="align-bottom mx-2" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      )) : null}
                    </Col>
                    <Col />
                  </Row>
                  <Row>
                    <Col>
                      <Button size="sm" className="mb-2" onClick={addOption}>+Options</Button>
                    </Col>
                  </Row>
                  {showDefault
                    ? (
                      <Row>
                        <Col>
                          <Form.Label>Default Option</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="Choose..."
                            placeholder="Group"
                            value={defaultOption}
                            onChange={(e) => setOptions({
                              showOptions, options, defaultOption: e.target.value, showDefault,
                            })}
                            ref={txtDefaultOption}
                          >
                            <option select>Choose...</option>
                            {options ? options.map((o) => <option>{o}</option>) : null}
                          </Form.Control>
                        </Col>
                        <Col />
                      </Row>
                    )
                    : null}
                </>
              )
              : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {props.id
            ? (
              <Button variant="primary" onClick={handleEdit}>
                Save
              </Button>
            )
            : (
              <Button variant="primary" onClick={handleAdd}>
                Add
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalDeleteField = (props) => {
    const handleClose = () => props.setShow(false);
    const handleDelete = () => { props.delete(props.id); props.setShow(false); };

    return (
      <Modal show={props.showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Field?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalNewGroup = (props) => {
    const txtGroupName = useRef();

    const validate = () => {
      const groupname = txtGroupName.current.value.trim();
      if (groupname.length === 0
        || groups.filter(({ name, id = -1 }) => groupname === name
        && id !== props?.id).length > 0) {
        txtGroupName.current.focus();
        return false;
      }

      return true;
    };

    const handleClose = () => props.setShow(false);
    const handleAdd = () => {
      if (!validate()) return;

      props.add({
        id: (Math.max(...groups.map((g) => parseFloat(g.id))) + 1).toString(),
        name: txtGroupName.current.value,
        fixed: false,
        type: props.type,
      });

      props.setShow(false);
    };

    const handleEdit = () => {
      if (!validate()) return;

      props.edit({
        id: props.id,
        name: txtGroupName.current.value,
        fixed: false,
      });

      props.setShow(false);
    };

    useEffect(() => {
      if (txtGroupName?.current && props.groupName !== '') txtGroupName.current.value = props.groupName;
      if (txtGroupName?.current) txtGroupName.current.focus();
    }, [props.groupName]);

    return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.id ? 'Edit Group' : 'Add Group'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label>Group Name</Form.Label>
                <Form.Control placeholder="Group Name" ref={txtGroupName} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {props.id
            ? (
              <Button variant="primary" onClick={handleEdit}>
                Save
              </Button>
            )
            : (
              <Button variant="primary" onClick={handleAdd}>
                Save
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalDeleteGroup = (props) => {
    const handleClose = () => props.setShow(false);
    const handleDelete = () => { props.delete(props.id); props.setShow(false); };

    return (
      <Modal show={props.showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Group?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalNewTab = (props) => {
    const txtTabName = useRef();

    const validate = () => {
      const tabname = txtTabName.current.value.trim();
      if (tabname.length === 0
        || tabs.filter(({ name, id = -1 }) => tabname === name
        && id !== props?.id).length > 0) {
        txtTabName.current.focus();
        return false;
      }

      return true;
    };

    const handleClose = () => props.setShow(false);
    const handleAdd = () => {
      if (!validate()) return;

      props.add({
        id: (Math.max(...tabs.map((g) => parseFloat(g.id))) + 1).toString(),
        name: txtTabName.current.value,
        fixed: false,
      });

      props.setShow(false);
    };

    const handleEdit = () => {
      if (!validate()) return;

      props.edit({
        id: props.id,
        name: txtTabName.current.value,
        fixed: false,
      });

      props.setShow(false);
    };

    useEffect(() => {
      if (txtTabName?.current && props.tabName !== '') txtTabName.current.value = props.tabName;
      if (txtTabName?.current) txtTabName.current.focus();
    }, [props.tabName]);

    return (
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.id ? 'Edit Tab' : 'Add Tab'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Label>Tab Name</Form.Label>
                <Form.Control placeholder="Tab Name" ref={txtTabName} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {props.id
            ? (
              <Button variant="primary" onClick={handleEdit}>
                Save
              </Button>
            )
            : (
              <Button variant="primary" onClick={handleAdd}>
                Save
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalDeleteTab = (props) => {
    const handleClose = () => props.setShow(false);
    const handleDelete = () => { props.delete(props.id); props.setShow(false); };

    return (
      <Modal show={props.showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Tab?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AccordionHeader = ({
    children, eventKey, index: groupIndex, callback,
  }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;

    const temp = groups[eventKey - 1];

    if (temp.fixed || (temp.type === 'L' && eventKey <= 3) || (temp.type === 'P' && temp.tab === '')) {
      console.log('fixed or built-in');
      return (
        <>
          <Button className="p-0" variant="link" onClick={decoratedOnClick}>{children}</Button>
          {isCurrentEventKey
            ? <CaretUpFill className="mt-1 float-right" onClick={decoratedOnClick} />
            : <CaretDownFill className="mt-1 float-right" onClick={decoratedOnClick} />}
        </>
      );
    }

    const batchGroups = groups.filter((g) => g.type === temp.type && (temp.type === 'L' || g.tab === temp.tab));
    const gIndex = batchGroups.findIndex((g) => g.id === temp.id);

    const upEnabled = (temp.type === 'L' && gIndex > 3)
      || (temp.type === 'P' && temp.tab === '' && gIndex > 2)
      || (temp.type === 'P' && gIndex > 0);

    const downEnabled = gIndex < batchGroups.length - 1;

    return (
      isCurrentEventKey
        ? (
          <>
            <Button className="font-weight-bold p-0" variant="link" onClick={decoratedOnClick}>{children}</Button>
            <CaretUpFill className="mt-1 float-right" onClick={decoratedOnClick} />
            <ButtonGroup className="pl-3 m-0">
              <Button variant="link" size="sm" className="mx-1 p-0">
                <Pencil onClick={(e) => clickEditGroup(e, groupIndex)} />
              </Button>
              <Button variant="link" size="sm" className="mx-1 p-0">
                <Trash onClick={(e) => clickDeleteGroup(e, groupIndex)} />
              </Button>
              <Button disabled={!upEnabled} variant="link" size="sm" className="mx-1 p-0">
                <ArrowUp onClick={(e) => clickUpGroup(e, groupIndex)} />
              </Button>
              <Button disabled={!downEnabled} variant="link" size="sm" className="mx-1 p-0">
                <ArrowDown onClick={(e) => clickDownGroup(e, groupIndex)} />
              </Button>
            </ButtonGroup>
          </>
        )
        : (
          <>
            <Button className="p-0" variant="link" onClick={decoratedOnClick}>{children}</Button>
            <CaretDownFill className="mt-1 float-right" onClick={decoratedOnClick} />
          </>
        )
    );
  };

  const getGroupIndex = (groupId) => groups.indexOf(groups.find((o) => o.id === groupId));

  const getFieldIndex = (fieldId) => fields.indexOf(fields.find((o) => o.id === fieldId));

  const updateFields = ({ tabs, groups, fields }, {
    type, tab = '', group = '', field = '', old = '',
  }, callback) => {
    const fieldsAndGroup = { tabs, groups, fields };

    let data = {};
    switch (type) {
      case 'fieldChangeOrder':
        data = { type, fields: fields.filter((f) => f.group === group) };
        break;
      case 'groupChangeOrder':
        data = { type, groups };
        break;
      case 'tabChangeOrder':
        data = { type, tabs };
        break;
      case 'addField':
        if (group && field) {
          data = { type, fields: fields.filter((f) => f.group === group && f.name === field) };
        } else {
          data = { type, fields };
        }
        break;
      case 'deleteField':
        data = { type, old: `${group}|${field}` };
        break;
      case 'editField':
        data = { type, old, fields: fields.filter((f) => f.group === group && f.name === field) };
        break;
      case 'addGroup':
        if (group) {
          data = { type, groups: groups.filter((g) => g.name === group) };
        } else {
          data = { type, groups };
        }
        break;
      case 'deleteGroup':
        data = { type, old: group };
        break;
      case 'editGroup':
        data = { type, old, groups: groups.filter((g) => g.name === group) };
        break;
      case 'addTab':
        if (tab) {
          data = { type, tabs: tabs.filter((g) => g.name === tab) };
        } else {
          data = { type, tabs };
        }
        break;
      case 'deleteTab':
        data = { type, old: tab };
        break;
      case 'editTab':
        data = { type, old, tabs: tabs.filter((g) => g.name === tab) };
        break;
      default:
        break;
    }

    return fieldsAPI.create(data).then(() => {
      if (callback) callback();
      setFields(fieldsAndGroup);
      displaySnack({ message: 'Save Successful.', variant: 'success' });
    }).catch(() => displaySnack({ message: 'Unable to Save', variant: 'danger' }));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    if (fields.filter((f) => f.id === result.draggableId && f.fixed).length > 0) return;
    if (result.destination.index < result.source.index && fields[result.destination.index].fixed) return;

    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateFields({ tabs, groups, fields: items }, { group: reorderedItem.group, type: 'fieldChangeOrder' });
  };

  useEffect(() => {
    setTitle(title);
    fieldsAPI.list().then((result) => {
      const { tabs, groups, fields } = result;
      setFields({ tabs, groups, fields });
    }).catch(() => displaySnack({ message: 'Loading Error', variant: 'danger' }));
  }, []);

  const clickAddTab = () => {
    setModalAttribute({ modalTabs: { show: true, showDelete: false, tabName: '' } });
  };

  const clickEditTab = () => {
    const { id, name } = tabs[selectedTab - 1];
    setModalAttribute({
      modalTabs: {
        show: true, showDelete: false, tabName: name, id,
      },
    });
  };

  const clickDeleteTab = () => {
    const { id } = tabs[selectedTab - 1];
    setModalAttribute({ modalTabs: { ...modalTabs, showDelete: true, id } });
  };

  const clickLeftTab = () => {
    const tabIndex = selectedTab - 1;
    if (tabIndex > 0) {
      const tempTabs = [...tabs];
      const temp = tempTabs[tabIndex];
      tempTabs[tabIndex] = tempTabs[tabIndex - 1];
      tempTabs[tabIndex - 1] = temp;
      updateFields({ tabs: tempTabs, groups, fields }, { type: 'tabChangeOrder' }, () => setSelectedTab((oldIndex) => parseInt(oldIndex, 10) - 1));
    }
  };

  const clickRightTab = () => {
    const tabIndex = selectedTab - 1;
    if (tabIndex < tabs.length - 1) {
      const tempTabs = [...tabs];
      const temp = tempTabs[tabIndex];
      tempTabs[tabIndex] = tempTabs[tabIndex + 1];
      tempTabs[tabIndex + 1] = temp;
      updateFields({ tabs: tempTabs, groups, fields }, { type: 'tabChangeOrder' }, () => setSelectedTab((oldIndex) => parseInt(oldIndex, 10) + 1));
    }
  };

  const clickAddGroup = (type) => {
    const tab = type === 'P' ? tabs[selectedTab - 1] : '';
    if (tab === undefined) return;

    setModalAttribute({
      modalGroups: {
        show: true, showDelete: false, groupName: '', type,
      },
    });
  };

  const clickEditGroup = (e, groupIndex) => {
    const { id, name } = groups[groupIndex];
    setModalAttribute({
      modalGroups: {
        ...modalGroups, show: true, showDelete: false, groupName: name, id,
      },
    });
  };

  const clickDeleteGroup = (e, groupIndex) => {
    const { id } = groups[groupIndex];
    setModalAttribute({ modalGroups: { ...modalGroups, showDelete: true, id } });
  };

  const clickAddField = (type) => {
    const tab = type === 'P' ? tabs[selectedTab - 1] : '';
    const tabName = tab === undefined ? '' : tab.name;

    setModalAttribute({
      modalFields: {
        show: true,
        showDelete: false,
        fieldName: '',
        fieldType: '',
        fixed: false,
        options_editable: false,
        group: '',
        options: [],
        defaultOption: '',
        type,
        tab: tabName,
      },
    });
  };

  const clickEditField = (e, fieldIndex) => {
    const {
      id, name, type, fixed, group, options_editable = false, options = [], defaultOption = '',
    } = fields[fieldIndex];

    const grp = groups.find((g) => g.name === group);

    setModalAttribute({
      modalFields: {
        show: true,
        showDelete: false,
        fieldName: name,
        id,
        fieldType: type,
        fixed,
        group,
        options_editable,
        options: [...options],
        defaultOption,
        type: grp.type,
        tab: grp.tab,
      },
    });
  };

  const clickDeleteField = (e, fieldIndex) => {
    const { id } = fields[fieldIndex];
    setModalAttribute({ modalFields: { ...modalFields, showDelete: true, id } });
  };

  const clickUpGroup = (e, groupIndex) => {
    const temp = groups[groupIndex];
    const batchGroups = groups.filter((g) => g.type === temp.type && (temp.type === 'L' || g.tab === temp.tab));
    const gIndex = batchGroups.findIndex((g) => g.id === temp.id);

    if ((temp.type === 'L' && gIndex > 3)
      || (temp.type === 'P' && temp.tab === '' && gIndex > 2)
      || (temp.type === 'P' && gIndex > 0)) {
      const groupIndex2 = groups.findIndex((g) => g.id === batchGroups[gIndex - 1].id);
      groups[groupIndex] = groups[groupIndex2];
      groups[groupIndex2] = temp;
      updateFields({ tabs, groups, fields }, { type: 'groupChangeOrder' });
      setAccordionIndex(groupIndex2 + 1);
    }
  };

  const clickDownGroup = (e, groupIndex) => {
    const temp = groups[groupIndex];
    const batchGroups = groups.filter((g) => g.type === temp.type && (temp.type === 'L' || g.tab === temp.tab));
    const gIndex = batchGroups.findIndex((g) => g.id === temp.id);

    if (gIndex < batchGroups.length - 1) {
      const groupIndex2 = groups.findIndex((g) => g.id === batchGroups[gIndex + 1].id);
      groups[groupIndex] = groups[groupIndex2];
      groups[groupIndex2] = temp;
      updateFields({ tabs, groups, fields }, { type: 'groupChangeOrder' });
      setAccordionIndex(groupIndex2 + 1);
    }
  };

  const addField = (props) => {
    updateFields({ tabs, groups, fields: [...fields, { ...props }] },
      { field: props.name, group: props.group, type: 'addField' });
  };

  const editField = (props) => {
    const oldField = fields.find((g) => g.id === props.id);
    if (oldField) {
      const index = fields.indexOf(oldField);
      const newField = { ...oldField, ...props };
      fields[index] = newField;
      updateFields({ tabs, groups, fields }, {
        field: newField.name, group: newField.group, old: oldField.name, type: 'editField',
      });
    }
  };

  const deleteField = (id) => {
    const oldField = fields.find((g) => g.id === id);
    if (oldField) {
      const index = fields.indexOf(oldField);
      fields.splice(index, 1);
      updateFields({ tabs, groups, fields }, { field: oldField.name, group: oldField.group, type: 'deleteField' });
    }
  };

  const addTab = (props) => {
    updateFields({ tabs: [...tabs, { ...props }], groups, fields },
      { tab: props.name, type: 'addTab' },
      () => setSelectedTab(tabs.length + 1));
  };

  const editTab = (props) => {
    const oldTab = tabs.find((t) => t.id === props.id);
    if (oldTab) {
      const oldTabName = oldTab.name;
      const index = tabs.indexOf(oldTab);
      groups.filter(({ tab }) => tab === oldTab.name).forEach((g) => { g.tab = props.name; });
      oldTab.name = props.name;
      tabs[index] = oldTab;
      updateFields({ tabs, groups, fields }, { tab: props.name, old: oldTabName, type: 'editTab' });
    }
  };

  const deleteTab = (id) => {
    const oldTab = tabs.find((g) => g.id === id);
    if (oldTab) {
      const index = tabs.indexOf(oldTab);
      const newFields = fields.filter(({ tab }) => tab !== oldTab.name);
      tabs.splice(index, 1);
      setSelectedTab((old) => Math.max(1, old - 1));
      updateFields({ tabs, groups, fields: newFields }, { tab: oldTab.name, type: 'deleteTab' });
    }
  };

  const addGroup = (props) => {
    const tab = props.type === 'P' ? tabs[selectedTab - 1].name : '';
    updateFields({ tabs, groups: [...groups, { ...props, tab }], fields }, { group: props.name, type: 'addGroup' });
  };

  const editGroup = (props) => {
    const oldGroup = groups.find((g) => g.id === props.id);
    if (oldGroup) {
      const oldGroupName = oldGroup.name;
      const index = groups.indexOf(oldGroup);
      fields.filter(({ group }) => group === oldGroup.name).forEach((f) => { f.group = props.name; });
      oldGroup.name = props.name;
      groups[index] = oldGroup;
      updateFields({ tabs, groups, fields }, { group: props.name, old: oldGroupName, type: 'editGroup' });
    }
  };

  const deleteGroup = (id) => {
    const oldGroup = groups.find((g) => g.id === id);
    if (oldGroup) {
      const index = groups.indexOf(oldGroup);
      const newFields = fields.filter(({ group }) => group !== oldGroup.name);
      groups.splice(index, 1);
      updateFields({ tabs, groups, fields: newFields }, { group: oldGroup.name, type: 'deleteGroup' });
    }
  };

  const tabTitle = (item, index) => ((selectedTab - 1 === index && !item.fixed) ? (
    <>
      <span className="align-middle">{`${item.name} `}</span>
      <ButtonGroup size="sm" className="p-0 m-0">
        <Button
          variant="link"
          size="sm"
          className="p-0 mr-2"
          onClick={clickEditTab}
        >
          <Pencil size={18} />
        </Button>
        <Button
          variant="link"
          size="sm"
          className="p-0 mr-2"
          onClick={clickDeleteTab}
        >
          <Trash size={18} />
        </Button>
        <Button
          variant="link"
          size="sm"
          className="p-0 mr-2"
          onClick={clickLeftTab}
          disabled={selectedTab <= 1}
        >
          <ArrowLeft size={18} />
        </Button>
        <Button
          variant="link"
          size="sm"
          className="p-0 mr-2"
          onClick={clickRightTab}
          disabled={selectedTab >= tabs.length}
        >
          <ArrowRight size={18} />
        </Button>
      </ButtonGroup>
    </>
  ) : item.name);

  const modals = useMemo(() => (
    <>
      <ModalNewTab
        id={modalTabs.id}
        show={modalTabs.show}
        tabName={modalTabs.tabName}
        setShow={(sw) => setModalAttribute({ modalTabs: { show: sw } })}
        add={addTab}
        edit={editTab}
      />
      <ModalNewGroup
        id={modalGroups.id}
        show={modalGroups.show}
        groupName={modalGroups.groupName}
        type={modalGroups.type}
        setShow={(sw) => setModalAttribute({ modalGroups: { show: sw } })}
        add={addGroup}
        edit={editGroup}
      />
      <ModalNewField
        id={modalFields.id}
        show={modalFields.show}
        fieldName={modalFields.fieldName}
        fieldType={modalFields.fieldType}
        group={modalFields.group}
        options={modalFields.options}
        defaultOption={modalFields.defaultOption}
        type={modalFields.type}
        tab={modalFields.tab}
        setShow={(sw) => setModalAttribute({ modalFields: { show: sw } })}
        add={addField}
        edit={editField}
      />
      <ModalDeleteTab
        id={modalTabs.id}
        showDelete={modalTabs.showDelete}
        tabName={modalTabs.tabName}
        setShow={(sw) => setModalAttribute({ modalTabs: { showDelete: sw } })}
        delete={deleteTab}
      />
      <ModalDeleteGroup
        id={modalGroups.id}
        showDelete={modalGroups.showDelete}
        groupName={modalGroups.groupName}
        type={modalGroups.type}
        setShow={(sw) => setModalAttribute({ modalGroups: { showDelete: sw } })}
        delete={deleteGroup}
      />
      <ModalDeleteField
        id={modalFields.id}
        showDelete={modalFields.showDelete}
        fieldName={modalFields.fieldName}
        group={modalFields.group}
        setShow={(sw) => setModalAttribute({ modalFields: { showDelete: sw } })}
        delete={deleteField}
      />
    </>
  ), [modalTabs, modalGroups, modalFields]);

  const GroupAccordion = ({ type, tab }) => (
    <Accordion style={{ width: '85%' }} activeKey={accordionIndex} onSelect={(v) => setAccordionIndex(v)}>
      {groups.filter((g) => g.type === type && (type === 'L' || g.tab === tab)).map(({ name: groupName, id }) => {
        const groupIndex = getGroupIndex(id);
        return (
          <Card className="mb-3 border shadow text-dark bg-white rounded">
            <Card.Header className="bg-white border-0">
              <AccordionHeader
                eventKey={groupIndex + 1}
                index={groupIndex}
              >
                {groupName}
              </AccordionHeader>
            </Card.Header>
            <Accordion.Collapse eventKey={groupIndex + 1}>
              <Card.Body>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <table cellPadding="5" className="field-table" width="100%" {...provided.droppableProps} ref={provided.innerRef}>
                        <colgroup>
                          <col width="20px" />
                          <col width="*" />
                          <col width="150px" />
                          <col width="150px" />
                        </colgroup>
                        <tbody>
                          {fields
                            .filter(({ group }) => group === groupName)
                            .map(({ id, name, type, fixed, options_editable = false }) => {
                              const fieldIndex = getFieldIndex(id);
                              return (
                                <Draggable key={name} draggableId={name} index={fieldIndex}>
                                  {(provided) => (
                                    <tr
                                      className={(fixed && !options_editable) ? '' : 'moveable'}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td><i className="small material-icons mx-0" style={{ color: 'gray' }}>{fixed ? '' : 'menu'}</i></td>
                                      <td md={{ size: 'auto' }}>{ name }</td>
                                      <td md={2} lg={2} xl={2} className="text-left">{ type }</td>
                                      <td md={4} lg={3} xl={2} className="text-right">
                                        {(!fixed || options_editable) && (
                                          <ButtonGroup size="sm" className="p-0 m-0">
                                            <Button
                                              variant="link"
                                              size="sm"
                                              className="p-0 mr-2"
                                              onClick={(e) => clickEditField(e, fieldIndex)}
                                              disabled={fixed && !options_editable ? 'disabled' : ''}
                                            >
                                              <PencilSquare size="18" />
                                            </Button>
                                            <Button
                                              variant="link"
                                              size="sm"
                                              className="p-0 m-0"
                                              onClick={(e) => clickDeleteField(e, fieldIndex)}
                                              disabled={fixed ? 'disabled' : ''}
                                            >
                                              <Trash size="18" />
                                            </Button>
                                          </ButtonGroup>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );

  return (
    <>
      {modals}
      <Tabs
        fill
        className="lead-tabs h5 my-2"
        style={{ width: '50%' }}
      >
        <Tab eventKey="lead-fields" title="Leads">
          <div className="mb-3 mt-3" style={{ width: '85%' }}>
            <Button className="mr-2" onClick={() => clickAddGroup('L')}>+ Group</Button>
            <Button className="mr-2" onClick={() => clickAddField('L')}>+ Field</Button>
          </div>
          <GroupAccordion type="L" />
        </Tab>
        <Tab eventKey="project-fields" title="Project Management">
          <div className="mb-3 mt-3" style={{ width: '85%' }}>
            <Button className="mr-2" onClick={() => clickAddGroup('P')}>+ Group</Button>
            <Button className="mr-2" onClick={() => clickAddField('P')}>+ Field</Button>
          </div>
          <GroupAccordion type="P" tab="" />
          <Tabs
            style={{ width: '85%' }}
            className="pill my-2"
            defaultActiveKey={1}
            activeKey={selectedTab}
            onSelect={(idx) => { if (idx <= tabs.length) setSelectedTab(idx); else clickAddTab(); }}
          >
            {tabs && tabs.map((item, index) => (
              <Tab eventKey={index + 1} title={tabTitle(item, index)}>
                <GroupAccordion type="P" tab={item.name} />
              </Tab>
            ))}
            <Tab title="Permit Package" disabled />
            { tabs && <Tab eventKey={tabs.length + 1} title={<Plus />} /> }
          </Tabs>
        </Tab>
      </Tabs>

    </>
  );
};

export default AdditionalFields;
