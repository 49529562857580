import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Button, ButtonGroup, Tab, Tabs, Spinner, Form } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import Save from 'components/Save';

import { AuthContext } from 'api/context';
import ValidatedForm, { FloatInput, FloatSelect } from 'components/Form/Inputs';
import { stateOptions, countryOptions } from 'options';
import { convertStateToAbbr } from 'options';

const UtilityRate = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const ratesEndpoint = newEndpoint('hardware/rates/');
  const utilitiesEndpoint = newEndpoint('hardware/utilities/');

  // all design rates
  const [newRate, setNewRate] = useState();
  const [newStateDefault, setNewStateDefault] = useState();
  const [companyRates, setCompanyRates] = useState([]);

  const [states, setStates] = useState([]);
  const [utilityCompanies, setUtilityCompanies] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [allUtilities, setAllUtilities] = useState([]);

  const countries = countryOptions.map((el) => ({ name: el }));

  useEffect(() => {
    if (newStateDefault && newStateDefault?.country) {
      const stateList = stateOptions[newStateDefault?.country].map((el) => ({ name: el }));
      setStates(stateList);
    }
  }, [newStateDefault?.country, newStateDefault?.state]);

  useEffect(() => {
    if (newRate && newRate?.country) {
      const stateList = stateOptions[newRate.country].map((el) => ({ name: el }));
      setStates(stateList);

      if (newRate.country === 'Canada'){
        setSpinner(true);
        utilitiesEndpoint.list(`?country=CA`).then((data) => {
          setUtilityCompanies(data);
          setSpinner(false);
        });
      } else if (newRate.country === 'United States' && newRate?.state) {
        setSpinner(true);
        utilitiesEndpoint.list(`?state=${convertStateToAbbr(newRate?.state, 'abbr')}`).then((data) => {
          setUtilityCompanies(data);
          setSpinner(false);
        });
      }
    }
  }, [newRate?.country, newRate?.state]);


  useEffect(() => {
    ratesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setCompanyRates(data);
      }
    });
    
    utilitiesEndpoint.list().then((data) => {
      if (data.length !== 0) {
        setAllUtilities(data);
      }
    });
  }, []);

  const handleUpdateRate = (value, field, index) => {
    if (field === 'offset_savings') {
      const updated = Array.from(companyRates);
      updated[index]['offset_savings'] = value;
      setCompanyRates(updated);
    }
    const updated = Array.from(companyRates);
    updated[index][field] = value;
    setCompanyRates(updated);
  };

  const postRateHandler = (rateToUpdate) => {
    const { id, ...totalRate } = rateToUpdate;

    if (id) {
      return ratesEndpoint.update(id, {...totalRate, utility_company: totalRate?.utility_company?.id})
        .then(() => displaySnack({ variant: 'success', message: `Updated ${rateToUpdate.name}.` }))
        .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${rateToUpdate.name}.` }));
    }
    return ratesEndpoint.create({...totalRate, utility_company: totalRate?.utility_company})
      .then(() => ratesEndpoint.list().then((data) => setCompanyRates(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${rateToUpdate.name}.` });
        setNewRate();
        setNewStateDefault();
      })
      .catch(() => {
        displaySnack({
          variant: 'danger',
          message: 'Failed to add new rate',
        });
      });
  };

  const handleDelete = (index) => {
    const updated = Array.from(companyRates);
    const rm = updated.splice(index, 1)[0];
    ratesEndpoint.remove(rm.id)
      .then(() => { setCompanyRates(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const initRate = () => setNewRate({ name: '', country: 'United States', state: '', offset_savings: false });

  const initStateDefault = () => setNewStateDefault({ name: '', country: 'United States', state: '', offset_savings: false });

  const nostateItem = companyRates.find((s) => s.state == null && s.name === 'default') ?? { name: 'default', country: null, state: null, offset_savings: false };
  let nostateIndex = companyRates.indexOf(nostateItem);
  if (nostateIndex < 0) {
    companyRates.push(nostateItem);
    nostateIndex = companyRates.length - 1;
  }

  return (
    <>
      <Tabs defaultActiveKey="all-states" id="uncontrolled-tab-example" className="mb-3 p-2 nav-pills nav-fill">
        <Tab eventKey="all-states" title="All States/Provinces">
          <ValidatedForm onSave={() => postRateHandler(nostateItem)}>
            <Row>
              <Col style={{ display: "none" }} sm={3} xs={12}>
                <FloatInput
                  required
                  label="Name"
                  value="default"
                  onChange={(e) => handleUpdateRate(e.target.value, 'name', nostateIndex)}
                  disabled
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Fixed Charge"
                  prepend="$"
                  value={nostateItem.fixed_charge || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', nostateIndex)}
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Rate Amount"
                  prepend="$"
                  value={nostateItem.rate_amount || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', nostateIndex)}
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Utility Fixed Solar Cost"
                  prepend="$"
                  value={nostateItem?.ufsc || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', nostateIndex)}
                />
              </Col>
              <Col sm={3} xs={12}>
                <FloatInput
                  required
                  label="Export Rate"
                  prepend="$"
                  value={nostateItem.export_rate || ''}
                  onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', nostateIndex)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <Form.Check
                  id="utility-offset-savings"
                  checked={nostateItem?.offset_savings}
                  onChange={() => handleUpdateRate(!nostateItem?.offset_savings, 'offset_savings', nostateIndex)}
                  label="Savings to calculate past 100% offset"
                />
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Button type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </ValidatedForm>

          <hr />
          <Row className="mt-3 mb-4">
            <Col>
              <strong>Default Rate For States:</strong>
            </Col>
          </Row>

          {companyRates.map((item, index) => (item?.state !== null) && (item?.name.includes('-default')) && (
            <Row>
              <Col>
                <ValidatedForm key={item?.id} onSave={() => postRateHandler(item)}>
                  <Row>
                    <Col sm={3} xs={12}>
                      <FloatSelect
                        label="Country"
                        nullValue="Choose Country"
                        options={countries}
                        labelField="name"
                        value={item.country}
                        onChange={(e) => handleUpdateRate(e.target.value, 'country', index)}
                        required
                        disabled
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatSelect
                        required
                        label="State"
                        nullValue="Choose State"
                        options={stateOptions[item?.country].map((el) => ({ name: el }))}
                        labelField="name"
                        value={item.state || ''}
                        onChange={(e) => { handleUpdateRate(e.target.value, 'state', index);handleUpdateRate(`${e.target.value}-default`, 'name', index); }}
                        disabled
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <FloatInput
                        required
                        label="Name"
                        value={item.name || ''}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Fixed Charge"
                        prepend="$"
                        value={item?.fixed_charge || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', index)}
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Rate Amount"
                        prepend="$"
                        value={item?.rate_amount || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', index)}
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Utility Fixed Solar Cost"
                        prepend="$"
                        value={item?.ufsc || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', index)}
                      />
                    </Col>
                    <Col sm={3} xs={12}>
                      <FloatInput
                        required
                        label="Export Rate"
                        prepend="$"
                        value={item?.export_rate || ''}
                        onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', index)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='mt-3'>
                      <Form.Check
                        id="utility-offset-savings"
                        checked={item?.offset_savings}
                        onChange={() => handleUpdateRate(!item?.offset_savings, 'offset_savings', index)}
                        label="Savings to calculate past 100% offset"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-3 float-right">
                    <Col>
                      <ButtonGroup size="sm">
                        <Button variant="outline-primary p-2" type="submit">
                          <Save />
                        </Button>
                        <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                          <Trash />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ValidatedForm>
              </Col>
            </Row>
          ))}

          {newStateDefault === undefined ? (
            <Row className="mt-2">
              <Col>
                <Button onClick={initStateDefault}>
                  + Rate
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm onSave={() => postRateHandler(newStateDefault)}>
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      label="Country"
                      nullValue="Choose Country"
                      options={countries}
                      labelField="name"
                      value={newStateDefault.country || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, country: e.target.value })}
                      required
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State"
                      nullValue="Choose State"
                      options={states}
                      labelField="name"
                      value={newStateDefault.state || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, state: e.target.value, name: `${e.target.value}-default` })}
                    />
                  </Col>
                  <Col sm={6} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      value={newStateDefault.name || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, name: e.target.value })}
                      disabled
                    />
                  </Col>
                  
                </Row>
                <Row className="mt-3">
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Fixed Charge"
                      prepend="$"
                      value={newStateDefault.fixed_charge || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, fixed_charge: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Rate Amount"
                      prepend="$"
                      value={newStateDefault.rate_amount || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, rate_amount: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Utility Fixed Solar Cost"
                      prepend="$"
                      value={newStateDefault.ufsc || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, ufsc: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Export Rate"
                      prepend="$"
                      value={newStateDefault.export_rate || ''}
                      onChange={(e) => setNewStateDefault({ ...newStateDefault, export_rate: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row>
                    <Col xs={12} className='mt-3'>
                      <Form.Check
                        id="utility-offset-savings"
                        checked={newStateDefault?.offset_savings}
                        onChange={() => setNewStateDefault({ ...newStateDefault, offset_savings: !newStateDefault?.offset_savings })}
                        label="Savings to calculate past 100% offset"
                      />
                    </Col>
                </Row>
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type="submit">
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewStateDefault()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
        <Tab eventKey="per-state" title="Per State/Province">
          {companyRates.map((item, index) => (
            (item?.state !== null && !item?.name.includes('-default') && (
              <Row>
                <Col>
                  <ValidatedForm key={item?.id} onSave={() => postRateHandler(item)}>
                    <Row>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Name"
                          value={item.name || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'name', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          label="Country"
                          nullValue="Choose Country"
                          options={countries}
                          labelField="name"
                          value={item.country}
                          onChange={(e) => handleUpdateRate(e.target.value, 'country', index)}
                          required
                          disabled
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          required
                          label="State"
                          nullValue="Choose State"
                          options={stateOptions[item?.country].map((el) => ({ name: el }))}
                          labelField="name"
                          value={item.state || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'state', index)}
                          disabled
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatSelect
                          required
                          label="Utility Company"
                          nullValue="Choose Utility Company"
                          options={item?.country === 'Canada' ? allUtilities.filter((el) => el.country === item?.country) : allUtilities.filter((el) => el.country === item?.country && el.state === item?.state)}
                          labelField="name"
                          value={item.utility_company?.id || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'utility_company', index)}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Fixed Charge"
                          prepend="$"
                          value={item?.fixed_charge || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'fixed_charge', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Rate Amount"
                          prepend="$"
                          value={item?.rate_amount || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'rate_amount', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Utility Fixed Solar Cost"
                          prepend="$"
                          value={item?.ufsc || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'ufsc', index)}
                        />
                      </Col>
                      <Col sm={3} xs={12}>
                        <FloatInput
                          required
                          label="Export Rate"
                          prepend="$"
                          value={item?.export_rate || ''}
                          onChange={(e) => handleUpdateRate(e.target.value, 'export_rate', index)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className='mt-3'>
                        <Form.Check
                          id="utility-offset-savings"
                          checked={item?.offset_savings}
                          onChange={(e) => handleUpdateRate(!item?.offset_savings, 'offset_savings', index)}
                          label="Savings to calculate past 100% offset"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3 float-right">
                      <Col>
                        <ButtonGroup size="sm">
                          <Button variant="outline-primary p-2" type="submit">
                            <Save />
                          </Button>
                          <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                            <Trash />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </ValidatedForm>
                </Col>
              </Row>
            ))
          ))}

          {newRate === undefined ? (
            <Row className="mt-2">
              <Col>
                <Button onClick={initRate}>
                  + Rate
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <ValidatedForm onSave={() => postRateHandler(newRate)}>
                <Row>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Name"
                      value={newRate.name || ''}
                      onChange={(e) => setNewRate({ ...newRate, name: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      label="Country"
                      nullValue="Choose Country"
                      options={countries}
                      labelField="name"
                      value={newRate.country || ''}
                      onChange={(e) => setNewRate({ ...newRate, country: e.target.value })}
                      required
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatSelect
                      required
                      label="State"
                      nullValue="Choose State"
                      options={states}
                      labelField="name"
                      value={newRate.state || ''}
                      onChange={(e) => setNewRate({ ...newRate, state: e.target.value })}
                    />
                    </Col>
                    <Col sm={3} xs={12}>
                    {spinner ? (
                      <Spinner animation="border" />
                      ) : (
                      <FloatSelect
                          required
                          label="Utility Company"
                          nullValue="Choose Utility Company"
                          options={utilityCompanies}
                          labelField="name"
                          value={newRate?.utility_company || ''}
                          onChange={(e) => {
                            setNewRate({ ...newRate, utility_company: e.target.value });
                          }}
                      /> )
                    }
                  </Col>
                </Row>
                <Row className="mt-3">
                  
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Fixed Charge"
                      prepend="$"
                      value={newRate.fixed_charge || ''}
                      onChange={(e) => setNewRate({ ...newRate, fixed_charge: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Rate Amount"
                      prepend="$"
                      value={newRate.rate_amount || ''}
                      onChange={(e) => setNewRate({ ...newRate, rate_amount: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Utility Fixed Solar Cost"
                      prepend="$"
                      value={newRate.ufsc || ''}
                      onChange={(e) => setNewRate({ ...newRate, ufsc: e.target.value })}
                    />
                  </Col>
                  <Col sm={3} xs={12}>
                    <FloatInput
                      required
                      label="Export Rate"
                      prepend="$"
                      value={newRate.export_rate || ''}
                      onChange={(e) => setNewRate({ ...newRate, export_rate: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='mt-3'>
                    <Form.Check
                      id="utility-offset-savings"
                      checked={newRate?.offset_savings}
                      onChange={() => setNewRate({ ...newRate, offset_savings: !newRate?.offset_savings })}
                      label="Savings to calculate past 100% offset"
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 float-right">
                  <Col>
                    <ButtonGroup size="sm">
                      <Button variant="outline-primary p-2" type="submit">
                        Save
                      </Button>
                      <Button variant="outline-primary p-2" onClick={() => setNewRate()}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </ValidatedForm>
            </>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default UtilityRate;
