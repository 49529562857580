import React from 'react';
import PropTypes from 'prop-types';
import { ChatRightQuote, X } from 'react-bootstrap-icons';
import './ChatFrame.css';

function ChatFrame(props) {
  const {
    showChatbox,
    clickIcon,
    unreadCount,
    children,
  } = props;

  return (
    <div className="react-chat-frame">
      <div className="react-chat-frame-wrapper">
        {showChatbox ? (
          <>
            <X className="react-chat-close-icon" onClick={clickIcon} />
            {children}
          </>
        ) : (
          <button
            type="button"
            className="react-chat-frame-icon-container"
            onClick={clickIcon}
          >
            {unreadCount > 0 && (
              <span className="notification-badge">
                {unreadCount}
              </span>
            )}
            <ChatRightQuote className="m-auto text-white" size={32} />
          </button>
        )}
      </div>
    </div>
  );
}

ChatFrame.propTypes = {
  // icon: PropTypes.element,
  showChatbox: PropTypes.bool.isRequired,
  clickIcon: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default ChatFrame;
