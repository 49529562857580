/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

function MessageBox(props) {
  const {
    type,
    timestamp,
    timestampFormat,
    buttons,
    left,
    author,
    hasError,
    text,
  } = props;
  if (type === 'text' || type === 'indicator') {
    let time;
    if (timestamp) {
      if (timestampFormat === 'calendar') {
        time = moment(timestamp).calendar();
      } else if (timestampFormat === 'fromNow') {
        time = moment(timestamp).fromNow();
      } else {
        time = moment(timestamp).format(timestampFormat);
      }
    }

    const _buttons = buttons
      ? buttons.map((button) => (
        <a
          href={button.payload}
          rel="noreferrer"
          target="_blank"
          className="react-chat-message-button"
        >
          {button.title}
        </a>
      ))
      : [];

    return (
      <div
        className={`react-chat-messageBox ${left ? 'react-chat-messageBoxLeft' : 'react-chat-messageBoxRight'}`}
      >
        <img
          alt="avater img"
          src={author.avatarUrl}
          className={`react-chat-avatar ${left ? 'react-chat-avatarLeft' : 'react-chat-avatarRight'}`}
        />
        <div
          className={`react-chat-message ${left ? 'react-chat-messageLeft' : 'react-chat-messageRight'}`}
        >
          <div className="react-chat-additional">{author.username}</div>
          <div
            className={`react-chat-bubble ${left ? 'react-chat-leftBubble' : 'react-chat-rightBubble'} ${hasError ? 'react-chat-bubbleWithError' : ''}`}
          >
            {type === 'indicator' && (
              <div className="react-chat-typing-indicator">
                <span />
                <span />
                <span />
              </div>
            )}
            {text}
            {_buttons.length > 0 && (
              <div
                className={
                  left
                    ? 'react-chat-message-buttonGroupLeft'
                    : 'react-chat-message-buttonGroupRight'
                }
              >
                {_buttons}
              </div>
            )}
            {hasError && (
              // <ErrorIcon
              //   className={`${
              //     left ? 'react-chat-errorLeft' : 'react-chat-errorRight'
              //   } react-chat-error`}
              // />
              <div>E</div>
            )}
          </div>
          <div className="react-chat-additional">{time !== null && time}</div>
        </div>
      </div>
    );
  }

  if (type === 'notification') {
    // show a new message indicator
    return (
      <div className="text-center text-secondary react-chat-notification">
        {text}
      </div>
    );
  }

  if (type === 'image') {
    let time;
    if (timestamp) {
      if (timestampFormat === 'calendar') {
        time = moment(timestamp).calendar();
      } else if (timestampFormat === 'fromNow') {
        time = moment(timestamp).fromNow();
      } else {
        time = moment(timestamp).format(timestampFormat);
      }
    }

    const _buttons = buttons
      ? buttons.map((button) => (
        <a
          href={button.payload}
          rel="noreferrer"
          target="_blank"
          className="react-chat-message-button"
        >
          {button.title}
        </a>
      ))
      : [];

    return (
      <div
        className={`react-chat-messageBox ${left ? 'react-chat-messageBoxLeft' : 'react-chat-messageBoxRight'}`}
      >
        <img
          alt="avater img"
          src={author.avatarUrl}
          className={`react-chat-avatar ${left ? 'react-chat-avatarLeft' : 'react-chat-avatarRight'}`}
        />
        <div
          className={`react-chat-message ${left ? 'react-chat-messageLeft' : 'react-chat-messageRight'}`}
        >
          <div className="react-chat-additional">{author.username}</div>
          <div
            className={`react-chat-bubble ${left ? 'react-chat-leftBubble' : 'react-chat-rightBubble'} ${hasError ? 'react-chat-bubbleWithError' : ''}`}
          >
            {type === 'indicator' && (
              <div className="react-chat-typing-indicator">
                <span />
                <span />
                <span />
              </div>
            )}
            {/* {text} */}
            <img src={`${text}`} style={{ width: '120px' }} alt="icon" />
            {_buttons.length > 0 && (
              <div
                className={
                  left
                    ? 'react-chat-message-buttonGroupLeft'
                    : 'react-chat-message-buttonGroupRight'
                }
              >
                {_buttons}
              </div>
            )}
            {hasError && (
              // <ErrorIcon
              //   className={`${
              //     left ? 'react-chat-errorLeft' : 'react-chat-errorRight'
              //   } react-chat-error`}
              // />
              <div>E</div>
            )}
          </div>
          <div className="react-chat-additional">{time !== null && time}</div>
        </div>
      </div>
    );
  }

  if (type === 'image-link') {
    let time;
    if (timestamp) {
      if (timestampFormat === 'calendar') {
        time = moment(timestamp).calendar();
      } else if (timestampFormat === 'fromNow') {
        time = moment(timestamp).fromNow();
      } else {
        time = moment(timestamp).format(timestampFormat);
      }
    }

    const _buttons = buttons
      ? buttons.map((button) => (
        <a
          href={button.payload}
          rel="noreferrer"
          target="_blank"
          className="react-chat-message-button"
        >
          {button.title}
        </a>
      ))
      : [];

    return (
      <div
        className={`react-chat-messageBox ${left ? 'react-chat-messageBoxLeft' : 'react-chat-messageBoxRight'}`}
      >
        <img
          alt="avater img"
          src={author.avatarUrl}
          className={`react-chat-avatar ${left ? 'react-chat-avatarLeft' : 'react-chat-avatarRight'}`}
        />
        <div
          className={`react-chat-message ${left ? 'react-chat-messageLeft' : 'react-chat-messageRight'}`}
        >
          <div className="react-chat-additional">{author.username}</div>
          <div
            className={`react-chat-bubble ${left ? 'react-chat-leftBubble' : 'react-chat-rightBubble'} ${hasError ? 'react-chat-bubbleWithError' : ''}`}
          >
            {type === 'indicator' && (
              <div className="react-chat-typing-indicator">
                <span />
                <span />
                <span />
              </div>
            )}
            {/* {text} */}
            Image not shown for performance reasons.
            <a href={`${text}`} target="_blank" rel="noreferrer" style={{ color: 'orange' }}>Click to see.</a>
            {_buttons.length > 0 && (
              <div
                className={
                  left
                    ? 'react-chat-message-buttonGroupLeft'
                    : 'react-chat-message-buttonGroupRight'
                }
              >
                {_buttons}
              </div>
            )}
            {hasError && (
              // <ErrorIcon
              //   className={`${
              //     left ? 'react-chat-errorLeft' : 'react-chat-errorRight'
              //   } react-chat-error`}
              // />
              <div>E</div>
            )}
          </div>
          <div className="react-chat-additional">{time !== null && time}</div>
        </div>
      </div>
    );
  }
}

export default MessageBox;
