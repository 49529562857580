import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, ProgressBar, Alert } from 'react-bootstrap';

import { AuthContext } from 'api/context';

const LicenseDetails = ({ company }) => {
  const { newEndpoint } = useContext(AuthContext);
  const CheckLimitAPI = newEndpoint('designer/limit/check');

  const [remained, setRemained] = useState();
  const [consumed, setConsumed] = useState();
  const [total, setTotal] = useState();
  const [status, setStatus] = useState();

  // get active license + total and consumed ai designs
  const license = company.active_license?.license_type;
  const designPackage = company?.active_design_package;
  // const [total, consumed] = company.design_packages
  //   .reduce(([tot, con], b) => [tot + b.total_designs, con + b.consumed], [0, 0]);

  const dptype = designPackage?.type?.subscription;

  useEffect(() => {
    CheckLimitAPI.send('POST', {company: company?.id})
      .then((res) => {
        setStatus(res?.status);
        setRemained(res?.remained);
      })
      .catch((err) => console.log(err));
    
  }, []);

  useEffect(() => {
    setTotal(designPackage?.total_designs);
    setConsumed(designPackage?.total_designs - remained);
  }, [remained]);

  const now = total ? Math.round(100 * (consumed / total)) : 100;
  const danger = total - consumed <= 0;
  const warning = !danger && total - consumed <= 10;
  // console.log(now, total, consumed, company);

  return (
    <>
      <hr />

      <Row className="my-3">
        <Col>
          <p className="d-inline">Subscription Plan</p>
          <p className="ml-2 p-2 rounded d-inline border border-dark">
            {dptype ? (dptype === 'M' ? 'Monthly' : 'Yearly') : 'N/A'}
          </p>
        </Col>
      </Row>
      <h4 className="my-2 font-weight-bold">Subscription & AI Design Package Information</h4>
      <Row className="my-0" style={{ maxWidth: '750px' }}>
        <Col>
          <h5 className="font-weight-bold">Current AI Design Usage</h5>
          <div>
            <strong>{consumed} / {total}</strong>
          </div>
          <ProgressBar now={now} max={total} striped />
        </Col>
      </Row>
      <Row className="my-2" style={{ maxWidth: '750px' }}>
        <Col>
          {warning && (
            <Alert variant="warning">{`You have ${total - consumed} Instant AI Designs left`}</Alert>
          )}
          {danger && (
            <Alert variant="danger">You’ve reached the limit for AI Designs. Purchase a new Design Package to continue using this feature.</Alert>
          )}
        </Col>
      </Row>
        
      {/*
        <Row className="mt-3">
          <Col>
            <p>{`Current AI design package usage: ${license.license_type.commitment_term === 'year' ? 'Yearly' : 'Monthly'}`}</p>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Button variant="primary" disabled>Purchase Additional AI Designs</Button>
          </Col>
        </Row> 
      */}
    </>
  );
};

export default LicenseDetails;
