import React, { useContext, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

import { AuthContext } from 'api/context';
import Login from './Login';
import SidePanel from './SidePanel';
import Screen from './Screen';
import Chat from '../Chat';

document.headx = document.head || document.getElementsByTagName('head')[0];

function changeFavicon(src) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.headx.removeChild(oldLink);
  }
  document.headx.appendChild(link);
}

const IFrame = lazy(() => import('IFrame'));

// Main
const Main = () => {
  const { user, login } = useContext(AuthContext);

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND === 'true') {
      document.title = 'Portal';
      changeFavicon('');
    }
  }, []);

  // returns only if loaded in browser
  if (window.location === window.parent.location) {
    return (
      <Route path="*">
        {user ? (
          <Container className="h-100">
            <Row className="h-100">
              <Screen />
              <SidePanel isAdmin={user.role === 'admin'} />
            </Row>
            <Chat />
          </Container>
        ) : <Login login={login} />}
      </Route>
    );
  }
  return null;
};

export default Main;
