import React, { Component } from 'react';
import update from 'immutability-helper';

import { AuthContext } from 'api/context';
import botAvatar from 'assets/images/logo-dark.jpg';
import ChatBox from './rcp/ChatBox';
import ChatFrame from './rcp/ChatFrame';
// import io from "socket.io-client"

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      show: false,
      unread: 0,
      counter: 0,
    };
  }

  componentDidMount() {
    // get context to create API endpoint and get user profile
    const { user, newEndpoint } = this.context;
    this.API = newEndpoint('chat/');

    // define user & bot profile
    this.botProfile = {
      id: 0,
      username: 'Aerialytic',
      avatarUrl: botAvatar,
    };
    this.userProfile = {
      id: 1,
      username: 'You',
      avatarUrl: user.profile || '/profile.png',
    };

    // default chat
    this.defaultMessages = [{
      author: this.botProfile,
      type: 'text',
      text: "Hi There, I'm a bot and will connect you to our support team.",
      // timestamp: new Date(),
    }];
    // this.getChat();
  }

  getChat = () => {
    const {
      show, lastRead = 0, counter = 0, messages: { length },
    } = this.state;

    // Don't make request if we updated in the last 3 seconds
    const time = new Date().getTime();
    if ((time - lastRead) < 3000) return;

    this.API.list()
      .then(({ reply_count: replies, profiles, messages: data }) => {
        console.log('REPLIES', replies);
        if (replies === 0) return;

        // if ((unreadReplies > 0) && (unreadReplies !== unread)) {
        const newMessages = data.slice(length).map(({ user: usr, type, text }) => {
          let author = usr === 'USER' ? this.userProfile : (profiles[usr] ?? this.botProfile);
          if (type === 'image-link') author = this.botProfile;
          return { author, type, text };
        });
        if (newMessages.length > 0) {
          this.setState(({ messages, unread }) => ({
            messages: [...messages, ...newMessages],
            unread: show ? 0 : unread + newMessages.length,
          }));
          console.log('New data not hibernating.');
        } else if (counter > 0 && show) this.setState({ counter: counter - 1 });
      });

    // wait longer between checks if chat is closed
    let timeout = 30000;

    // while chat is open, check more often for the first 5 loops, then slow it down
    if (show) timeout = counter === 0 ? 15000 : 6000;
    setTimeout(this.getChat, timeout);
  };

  // toggle showChatbox
  handleClickIcon = () => {
    const { show, lastRead } = this.state;

    // set unread to 0 if opening
    if (show) this.setState({ show: false });
    else {
      this.setState({ show: true, unread: 0 }, () => {
        if (lastRead === undefined) this.getChat();
      });
    }
  };

  handleOnSendMessage = (message) => {
    // it's a normal message to send to admin
    this.API.create({ type: 'message', message });
    this.setState((prev) => update(prev, {
      messages: {
        $push: [{
          author: this.userProfile,
          type: 'text',
          text: message,
          // timestamp: new Date(),
        }],
      },
    }));
  }

  handleOnSendFile = (file, filename) => {
    // show the file in the chat
    this.setState(({ messages }) => update(messages, {
      $push: {
        author: this.userProfile,
        text: file,
        type: 'image',
        // timestamp: new Date(),
      },
    }));

    // send image data as blob
    fetch(file).then(async (res) => {
      const form = new FormData();
      form.append('type', 'image');
      form.append('image', await res.blob(), filename);
      this.API.create(form);
    });
  }

  render() {
    const { show, messages, unread } = this.state;
    return (
      <ChatFrame
        unreadCount={unread}
        showChatbox={show}
        clickIcon={this.handleClickIcon}
      >
        <ChatBox
          userId={1}
          messages={messages.length > 0 ? messages : this.defaultMessages}
          // activeAuthor={this.userProfile}
          onSendMessage={this.handleOnSendMessage}
          onSendFile={this.handleOnSendFile}
          // showTypingIndicator
        />
      </ChatFrame>
    );
  }
}

Chat.contextType = AuthContext;
export default Chat;
