import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Paperclip, CursorFill } from 'react-bootstrap-icons';
import TextareaAutosize from 'react-textarea-autosize';

import { strip } from './utils';
import { onSendKeyType } from './props';

function InputBox({
  onSendMessage,
  placeholder,
  disabled,
  disabledPlaceholder,
  onSendKey,
  notifyFileBase64,
}) {
  const [inputText, setInputText] = useState('');
  const fileRef = useRef();

  const handleOnChange = (e) => {
    setInputText(e.target.value);
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      notifyFileBase64(reader.result, file.name);
    };
  };

  const sendMessage = (message) => {
    onSendMessage(message);
    setInputText('');
  };

  const handleOnClick = () => {
    const str = strip(inputText);
    if (str.length) {
      sendMessage(str);
    } else {
      // to do cannot send empty message
    }
  };

  const onKeyPress = (e) => {
    if ((onSendKey === null || e[onSendKey]) && e.charCode === 13) {
      const str = strip(inputText);
      if (str.length) {
        sendMessage(str);
      }
      e.preventDefault();
    }
  };

  return (
    <div className={`react-chat-inputBox ${disabled ? 'disabled' : ''}`}>
      <TextareaAutosize
        maxRows={3}
        className="react-chat-textarea"
        placeholder={disabled ? disabledPlaceholder : placeholder}
        value={inputText}
        onChange={handleOnChange}
        onKeyPress={onKeyPress}
        autoFocus
        disabled={disabled}
      />
      {/* for file upload */}

      <button
        type="button"
        className="react-chat-sendButton"
        onClick={() => fileRef.current.click()}

      >
        <Paperclip className="ml-4" />
      </button>
      <input
        ref={fileRef}
        type="file"
        className="d-none"
        accept="image/*"
        onChange={handleChangeFile}
      />

      <button
        type="button"
        className="react-chat-sendButton"
        onClick={handleOnClick}
        disabled={disabled}
      >
        <CursorFill />
      </button>

    </div>
  );
}

InputBox.defaultProps = {
  placeholder: '',
  disabled: false,
  disabledPlaceholder: '',
  onSendKey: null,
};

InputBox.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  onSendKey: onSendKeyType,
  notifyFileBase64: PropTypes.func.isRequired,
};

export default InputBox;
