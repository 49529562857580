import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { onSendKeyType, timestampFormatType, authorType } from './props';
import InputBox from './InputBox';
import MessageBox from './MessageBox';

import './ChatBox.css';

const ChatBox = ({
  userId,
  messages,
  // activeAuthor,
  timestampFormat,
  placeholder,
  disableInput,
  disabledInputPlaceholder,
  // showTypingIndicator,
  onSendMessage,
  onSendKey,
  onSendFile,
}) => {
  const messageContainer = useRef();

  const scrollToBottom = () => {
    if (messageContainer) {
      const { current } = messageContainer;
      current.scrollTop = current.scrollHeight - current.clientHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <div className="react-chat-container">
      <div className="react-chat-row">
        <div className="react-chat-viewerBox">
          <div
            className="react-chat-messagesList"
            ref={messageContainer}
          >
            <div className="react-chat-messagesListContent">
              {messages.map(({ author, type, text }, idx) => {
                const key = `${author.id}-${idx}`;
                return (
                  <MessageBox
                    key={key}
                    left={author && (author.id !== userId)}
                    timestampFormat={timestampFormat}
                    author={author}
                    type={type}
                    text={text}
                  />
                );
              })}
              {/* {showTypingIndicator && activeAuthor !== null && (
                <MessageBox
                  type="indicator"
                  author={activeAuthor}
                  text=""
                  left
                />
              )} */}
            </div>
          </div>
          <InputBox
            onSendMessage={onSendMessage}
            disabled={disableInput}
            placeholder={placeholder}
            disabledInputPlaceholder={disabledInputPlaceholder}
            onSendKey={onSendKey}
            notifyFileBase64={onSendFile}
          />
        </div>
      </div>
    </div>
  );
};

ChatBox.defaultProps = {
  timestampFormat: 'calendar',
  disableInput: false,
  disabledInputPlaceholder: '',
  placeholder: '',
  // showTypingIndicator: false,
  // activeAuthor: null,
  onSendKey: null,
};

ChatBox.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      author: authorType,
      type: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  onSendMessage: PropTypes.func.isRequired,
  timestampFormat: timestampFormatType,
  disableInput: PropTypes.bool,
  disabledInputPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  // showTypingIndicator: PropTypes.bool,
  // activeAuthor: authorType,
  onSendKey: onSendKeyType,
  onSendFile: PropTypes.func.isRequired,
};

export default ChatBox;
